(function () {
    'use strict';

    angular.module('madApp')
        .controller('dorRevenueController', dorRevenueController)
    ;

    dorRevenueController.$inject = ['$scope', '$state', '$filter', 'dorStateSummariesService', 'RevenueServices', 'AlertService','Principal'];
    function dorRevenueController($scope, $state, $filter, dorStateSummariesService, RevenueServices, AlertService,Principal) {
        var vm = this;
        vm.stateSummaryData = {};
        vm.currentState = 'vl.dashboards.revenue.collections';
        vm.queryData = {};
        vm.loadingAllUlbDataRecords = true;
        vm.loadingStateSummary = true;
        vm.firstcall = true;
        vm.loadingInner = true;
        vm.showDates = true;
        vm.formHidden = true;
        vm.ulbtype= null;
        vm.showAll = false;
        vm.conversionType = "Crores";
        vm.currencyUnits = "Crs";
        vm.flag = false;
        vm.officerType = null;
        vm.parentofficerType = null;
        vm.showdcbpenalties = Principal.isAuthenticated;
        vm.dcbpenaltyType = 'without_penalties';

	//TODO:move to state params
        $scope.$watch('vm.conversionType', function (newValue, oldValue) {
            if (newValue && newValue!=oldValue) {
              vm.conversionType = newValue;
              if(vm.conversionType == "Crores"){
                vm.currencyUnits = "Crs";
              }
              if(vm.conversionType == "Lakhs"){
                vm.currencyUnits = "Lacs";
              }
              vm.flag = true ;
              if ('vl.dashboards.revenue.collections' === vm.currentState) {
                loadCollectionsData();
              }
              else if('vl.dashboards.revenue.receipts' === vm.currentState){
                loadReceiptsData();
              }
              else if('vl.dashboards.revenue.top10' === vm.currentState){
                loadTop10Data();
              }
              else if('vl.dashboards.revenue.bottom10' === vm.currentState){
                loadBottom10Data();
              }
              else if('vl.dashboards.revenue.top100defaulters' === vm.currentState){
                loadDefaultersData();
              }
              else if('vl.dashboards.revenue.collectionranking' === vm.currentState){
                loadCollectionRankingData();
              }
              else if('vl.dashboards.revenue.dcbcourt' === vm.currentState
                        || 'vl.dashboards.revenue.dcbcentral' === vm.currentState ||
                        'vl.dashboards.revenue.dcbvacant' === vm.currentState ||
                        'vl.dashboards.revenue.dcbstate' === vm.currentState ||
                        'vl.dashboards.revenue.dcbprivate' === vm.currentState ||
                        'vl.dashboards.revenue.dcb' === vm.currentState
                    ){
                        callDCB();
              }
            }
          });
        
          $scope.$watch('vm.dcbpenaltyType', function (newValue, oldValue) {
            console.log(newValue)
            if (newValue && newValue!=oldValue) {
              vm.dcbpenaltyType = newValue;
              callDCB();
            }
          });

        vm.tabs = [
            {
                title: $filter('translate')("revenue.tabs.collections.title"),
                route: 'vl.dashboards.revenue.collections',
                loadingData: true,
                active: false
            },
            {
                title: $filter('translate')("revenue.tabs.dcb.title"),
                route: 'vl.dashboards.revenue.dcb',
                active: false

            },
            {
                title: $filter('translate')("revenue.tabs.receipts.title"),
                route: "vl.dashboards.revenue.receipts",
                active: false
            },
            {
                title: $filter('translate')("revenue.tabs.top10.title"),
                route: "vl.dashboards.revenue.top10",
                active: false
            },
            {
                title: $filter('translate')("revenue.tabs.bottom10.title"),
                route: "vl.dashboards.revenue.bottom10",
                active: false
            },
            {
                title: $filter('translate')("revenue.tabs.top100defaulters.title"),
                route: "vl.dashboards.revenue.top100defaulters",
                active: false
            },
            {
                title: $filter('translate')("revenue.tabs.collectionranking.title"),
                route: "vl.dashboards.revenue.collectionranking",
                active: false
            }
        ];

        vm.active = function (route) {
            return $state.is(route);
        };
        $scope.$on("$stateChangeSuccess", function () {

          if(vm.ulbtype !== null){
            if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
            || $state.params.type === "ULB_REVENUE_OFFICER") && $state.params.id){
              vm.conversionType = "Lakhs";
              vm.currencyUnits = "Lacs";
            }
            else{
              vm.conversionType = "Crores";
              vm.currencyUnits = "Crs";
            }
          }
          else{
            if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
            || $state.params.type === "ULB_REVENUE_OFFICER") && $state.params.id){
              vm.conversionType = "Lakhs";
              vm.currencyUnits = "Lacs";
            }

            else if($state.params.type==="ALL_WARD"){
              vm.conversionType = "Lakhs";
              vm.currencyUnits = "Lacs";
            }
            else{
              if(vm.currentState === $state.current.name){
                vm.conversionType = vm.conversionType;
                vm.currencyUnits = vm.currencyUnits;
              }else{
                vm.conversionType = "Crores";
                vm.currencyUnits = "Crs";
              }
            }
          }

          vm.ftype = $state.params.type;
          vm.fkey = $state.params.id;
          vm.formHidden = true;
          vm.ulbtype = null;
          vm.propertyType = null;
          vm.showProperty = false;
          vm.showAll = false;

          if(vm.ftype==='' && vm.fkey===''){
            vm.queryData = {
                    fromDate: null,
                    toDate: null,
                    gradeType: 'district',
                    grade: null,
                    selectedULB: null
                };
          }

          if($state.params.type === "ALL_ULB" || $state.params.type === "ALL_WARD" ||  $state.params.type === "ALL_SEC_WARD"){
            vm.showAll = true;
          }


            // vm.tabs.forEach(function (tab) {
            //     tab.active = vm.active(tab.route);
            //     if (tab.active) {
            //         vm.currentState = tab.route;
            //     }
            // });
            vm.currentState = $state.current.name;
            RevenueServices.setPropertyType(null);
            // All Load calls go here
            vm.officerType = null;
            vm.parentofficerType = null;
            if ('vl.dashboards.revenue.collections' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                callCollection();
            }
            if ('vl.dashboards.revenue.dcb' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                vm.officerType = null;
                // if(vm.dcb!=undefined)
                //   vm.dcb["options"] = null;
                callDCB();
            }
            if ('vl.dashboards.revenue.dcbprivate' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                vm.propertyType = "Private";
                // if(vm.dcb!=undefined)
                //   vm.dcb["options"] = null;
                callDCB();
            }
            if ('vl.dashboards.revenue.dcbstate' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                vm.propertyType = "State Government";
                // if(vm.dcb!=undefined)
                //   vm.dcb["options"] = null;
                callDCB();
            }
            if ('vl.dashboards.revenue.dcbcentral' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                vm.propertyType = "CENTRAL_GOVT";
                // if(vm.dcb!=undefined)
                //   vm.dcb["options"] = null;
                callDCB();
            }
            if ('vl.dashboards.revenue.dcbvacant' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                vm.propertyType = "Vacant Land";
                // if(vm.dcb!=undefined)
                //   vm.dcb["options"] = null;
                callDCB();
            }
            if ('vl.dashboards.revenue.dcbcourt' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER" || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                vm.propertyType = "Courtcases";
                // if(vm.dcb!=undefined)
                //   vm.dcb["options"] = null;
                callDCB();
            }
            if ('vl.dashboards.revenue.receipts' === vm.currentState) {
                vm.showDates = true;


                 if(($state.params.type === "ULB" || $state.params.type === "ULB_WARD") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                // if(vm.reciepts!=undefined)
                //   vm.reciepts["options"] = null;
                callreceipts();
            }
            if ('vl.dashboards.revenue.top10' === vm.currentState) {
                vm.showDates = true;
                if( ($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER"  || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                calltop10();
            }
            if ('vl.dashboards.revenue.bottom10' === vm.currentState) {
                vm.showDates = true;
                if(($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_INSPECTOR"
                || $state.params.type === "ULB_REVENUE_OFFICER"  || $state.params.type === "ULB_WARD"  || $state.params.type === "ULB_SEC_WARD_SECRETARY" 
                || $state.params.type === "ULB_SEC_REVENUE_INSPECTOR" || $state.params.type === "ULB_SEC_REVENUE_OFFICER") && $state.params.id)
                {
                    vm.ulbtype = $state.params.type;
                }
                else{
                    vm.ulbtype = null;
                }
                callbottom10();
            }
            if ('vl.dashboards.revenue.top100defaulters' === vm.currentState) {
                vm.showDates = false;
                vm.showProperty = true;
                vm.officerType = null;
                vm.parentofficerType = null;
                calldefaulters();
            }
            if ('vl.dashboards.revenue.collectionranking' === vm.currentState) {
                vm.showDates = false;
                vm.parentofficerType = 'secretariatward';
                vm.officerType = "wardsecretary";
                callCollectionRanking();
            }
        });
        loadStateSummary();
        function loadStateSummary() {
            dorStateSummariesService.stateSummaries.summary().$promise.then(dorStateSummaryCallSuccess, dorStateSummaryCallError);
            function dorStateSummaryCallSuccess(data) {
                vm.loadingStateSummary = false;
                vm.stateSummaryData = {
                    formattedDate: $filter('date')(data.calculatedOn, "dd-MMM-yyyy"),
                    formattedTime: $filter('date')(data.calculatedOn, "hh:mm a"),
                    totalRevenueAmount: ($filter('number')(data.totalRevenueCollected.amount)),
                    totalRevenueUnit: getAmountUnits(data.totalRevenueCollected.amount),
                    propertyTax: {
                        collected: {
                            amount: ($filter('number')(data.propertyTax.collected.amount)),
                            amountUnit: getAmountUnits(data.propertyTax.collected.amount),
                            variance: (data.propertyTax.varianceOverLastYear),
                            achievement: (data.propertyTax.achievementPercent)
                        },
                        lastYear: {
                            amount: ($filter('number')(data.propertyTax.collectedLastYear.amount)),
                            amountUnit: getAmountUnits(data.propertyTax.collectedLastYear.amount)
                        },
                        target: {
                          amount: ($filter('number')(data.propertyTax.target.amount)),
                          amountUnit: getAmountUnits(data.propertyTax.target.amount)}
                    },
                    waterCharges: {
                        collected: {
                            amount: ($filter('number')(data.waterCharges.collected.amount)),
                            amountUnit: getAmountUnits(data.waterCharges.collected.amount),
                            variance: (data.waterCharges.varianceOverLastYear),
                            achievement: (data.waterCharges.achievementPercent)
                        },
                        lastYear: {
                            amount: ($filter('number')(data.waterCharges.collectedLastYear.amount)),
                            amountUnit: getAmountUnits(data.waterCharges.collectedLastYear.amount)
                        },
                        target: {
                          amount: ($filter('number')(data.waterCharges.target.amount)),
                          amountUnit: getAmountUnits(data.waterCharges.target.amount)}

                    },
                    others: {
                        collected: {
                            amount: ($filter('number')(data.others.collected.amount)),
                            amountUnit: getAmountUnits(data.others.collected.amount),
                            variance: (data.others.varianceOverLastYear),
                            achievement: (data.others.achievementPercent)
                        },
                        lastYear: {
                            amount: ($filter('number')(data.others.collectedLastYear.amount)),
                            amountUnit: getAmountUnits(data.others.collectedLastYear.amount)
                        },
                        target: {
                          amount: ($filter('number')(data.others.target.amount)),
                          amountUnit: getAmountUnits(data.others.target.amount)}
                    }
                };
                vm.loadingStateSummary = false;
            }

            function dorStateSummaryCallError(error) {
              vm.loadingStateSummary = false;
              AlertService.error(error.data.message);

            }
        }

        function getAmountUnits(amount){
          if(amount){
            return "Crs";
          }else{
            return null;
          }
        }

        loadAllULBData();
        function loadAllULBData() {
            if (vm.ulbList) return;
            vm.loadingAllUlbDataRecords = true;
            RevenueServices.listAllUlBs().query().$promise.then(allUlbSuccess, allUlbError);
            function allUlbSuccess(data) {
                if (data.data) {
                    vm.ulbList = data.data;
                    vm.loadingAllUlbDataRecords = false;
                }
            }

            function allUlbError(error) {
                vm.loadingAllUlbDataRecords = false;
                AlertService.error(error.data.message);
            }

        }

        function setdates(from, to) {
            if (from) {
                $state.params.fromDate = from;
            }
            if (to) {
                $state.params.toDate = to;
            }
        }

        // callCollection();
        //    setTimeout(function () {
        //      callDCB();
        //      callreceipts();
        //      calltop10();
        //      callbottom10();
        //      calldefaulters();
        //    }, 8000);


        function callCollection(key, type, from, to) {
            //setdates(from, to);
            //collectionData(key, type);

            loadCollectionsData();
        }

        function callDCB(key, type, from, to) {
            //setdates(from, to);
            //dcbData(key, type);

            loadDCBData();
        }

        function callreceipts(key, type, from, to) {
            //setdates(from, to);
            //receiptsData(key, type);
            loadReceiptsData();
        }

        function calltop10(key, type, from, to) {
            //setdates(from, to);
            //top10Data(key, type);
            loadTop10Data();
        }

        function callbottom10(key, type, from, to) {
            //setdates(from, to);
            //bottom10Data(key, type);
            loadBottom10Data();
        }

        function calldefaulters(key, type, from, to) {
            //setdates(from, to);
            //top100DefaultersData(key, type);
            loadDefaultersData();
        }

        function callCollectionRanking(key, type, from, to) {
            //setdates(from, to);
            //top100DefaultersData(key, type);
            loadCollectionRankingData();
        }

        function convertDateTimezone(input){
            if(input){
                var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                var localISOTime = (new Date(input - tzoffset)).toISOString();
                return localISOTime;
            }else{
                return input;
            }

        }

        function isInitDistrict(){
          if(!$state.params.id && (!$state.params.type || $state.params.type==="DISTRICT") && !$state.params.fromDate && !$state.params.toDate && !vm.flag){
            return true;
          }
          else{
            return false;
          }
        }

        function formatTableData(tableData){
          var tablesList = angular.copy(tableData);
          var tables = tablesList["records"];
          angular.forEach(tables,function(table){
            var headersList  = [];
            var firstCol = [];
            var secondCol = [];
            var sortIndex = 0;
            var dataTypes = [];
            angular.forEach(table.headers,function(header){
              if(!header.span){
                header["sortIndex"] = sortIndex;
                header["rows"] = 2;
                header["cols"] = 1;
                firstCol.push(header);
                dataTypes.push(header.dataType);
                sortIndex++;
              }else if (header.span) {
                if(header.topHeader){
                  header.topHeader["rows"] = 1;
                  header.topHeader["cols"] = header.colspan;
                  firstCol.push(header.topHeader);
                }
                header["sortIndex"] = sortIndex;
                header["rows"] = 1;
                header["cols"] = 1;
                secondCol.push(header);
                dataTypes.push(header.dataType);
                sortIndex++;
              }
            });
            headersList.push(firstCol);
            headersList.push(secondCol);
            table.headers = headersList;
            table["dataTypes"] = dataTypes;
          });
          return tablesList;
        }


        var districtOrderList = [];
        function loadCollectionsData() {

            if(vm.collection!=undefined)
              vm.collection.options =null;
              var query = {
                  locKey: $state.params.id || null,
                  locationType : $state.params.type || null,
                  currencyUnit : vm.conversionType
              };
              if ($state.params.fromDate) {
                  query.fromDate = convertDateTimezone($state.params.fromDate);
              }
              if ($state.params.toDate) {
                  query.toDate =  convertDateTimezone($state.params.toDate);
              }
            if(isInitDistrict()==true && RevenueServices.getstate("collection")){
              collectionsSuccess(RevenueServices.getstate("collection"));
              return;
            }

            vm.tabs[0].loadingData = true;

            RevenueServices.getCollections().query(query).$promise.then(collectionsSuccess, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function collectionsSuccess(data) {
                console.log('Collection success!');
                var collection ={};
                collection.id = "coll_line";
                collection.TrendData = {
                    formattedFromDate: $filter('date')(data.trendData.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.trendData.toDate, "dd-MMM-yyyy"),
                    monthArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    chartData: data.trendData.chartData
                };
                collection.TotalSummary = {
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                    totalSummary: data.totalSummary
                };
                collection.TableData = {
                    records: data.revenueRecords,
                    locType: data.locationType,
                    formattedFromDate: data.fromDate,
                    formattedToDate: data.toDate
                };

                collection.options = {
                    xAxisArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    seriesData: data.trendData.chartData,
                    modifyYear:true
                };
                collection.key = query.locKey;
                collection.type = query.locationType;

                if($state.params.type === "ALL_ULB" || $state.params.type === "ALL_WARD"){
                  collection.TableData.records[0].limit =50;
                  collection.TableData.records[0].infiniteScroll = true;
                }



                if(isInitDistrict()==true){
                  RevenueServices.setstate(data,"collection");
                }
                collection.TableData = formatTableData(collection.TableData);
                vm.collection = collection;
                //after first collection call all other dashboards calls are made in background
                //only called when the page reloads
                if(vm.firstcall===true){
                     vm.propertyType = "Private";
                    //  callDCB();
                    //  callreceipts();
                    //  calltop10();
                    //  callbottom10();
                    //  calldefaulters();
                     vm.firstcall = false;
                   };
                   vm.tabs[0].loadingData = false;
            }



        }

        function loadDCBData() {
            console.log('Loading DCB content');



            if(vm.dcb!=undefined)
              vm.dcb.options =null;
              var query = {
                  locKey: $state.params.id || null,
                  locationType : $state.params.type || null,
                  currencyUnit : vm.conversionType,
                  penaltyType: vm.dcbpenaltyType
              };
              if ($state.params.fromDate) {
                  query.fromDate = convertDateTimezone($state.params.fromDate);
              }
              if ($state.params.toDate) {
                  query.toDate =  convertDateTimezone($state.params.toDate);
              }
              if(vm.propertyType){
                query.propertyType = vm.propertyType;
              }
            // if(isInitDistrict()==true && RevenueServices.getstate("DCB") && !vm.propertyType){
            //   DCBSuccess(RevenueServices.getstate("DCB"));
            //   console.log("caaa")
            //   return;
            // }
            vm.tabs[1].loadingData = true;

            RevenueServices.getDCB().query(query).$promise.then(DCBSuccess, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function DCBSuccess(data) {
                console.log('DCB success!');
                var dcb ={};
                dcb.id="dcb_line";
                dcb.TrendData = {
                    formattedFromDate: $filter('date')(data.trendData.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.trendData.toDate, "dd-MMM-yyyy"),
                    monthArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    chartData: data.trendData.chartData
                };
                dcb.TotalSummary ={
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                    totalSummary:data.totalSummary
                };
                dcb.TableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                dcb.options= {
                    xAxisArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    seriesData: data.trendData.chartData,
                    modifyYear:true
                }
                dcb.key=  query.locKey;
                dcb.type = query.locationType;

                if($state.params.type === "ALL_ULB" || $state.params.type === "ALL_WARD"){
                  dcb.TableData.records[0].limit =50;
                  dcb.TableData.records[0].infiniteScroll = true;
                }
                dcb.TableData = formatTableData(dcb.TableData);
                vm.dcb = dcb;
                vm.tabs[1].loadingData = false;
                if(isInitDistrict()==true && !vm.propertyType){
                  RevenueServices.setstate(data,"DCB");
                }
            }

        }

        function loadReceiptsData() {
            console.log('Loading Receipts content');
            if(vm.receipts!=undefined)
              vm.receipts.options = null;
              vm.tabs[2].loadingData = true;
              var query = {
                  locKey: $state.params.id || null,
                  locationType : $state.params.type || null,
                  currencyUnit : vm.conversionType
              };
              if ($state.params.fromDate) {
                  query.fromDate = convertDateTimezone($state.params.fromDate);
              }
              if ($state.params.toDate) {
                  query.toDate =  convertDateTimezone($state.params.toDate);
              }
            if(isInitDistrict()==true && RevenueServices.getstate("Receipts")){
              ReceiptsSuccess(RevenueServices.getstate("Receipts"));
              return;
            }

            RevenueServices.getReceipts().query(query).$promise.then(ReceiptsSuccess, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function ReceiptsSuccess(data) {
                console.log('Receipts success!');
                var receipts={};
                receipts.id="receipts_line";
                receipts.TrendData = {
                    formattedFromDate: $filter('date')(data.trendData.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.trendData.toDate, "dd-MMM-yyyy"),
                    monthArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    chartData: data.trendData.chartData
                };
                receipts.TotalSummary ={
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                    totalSummary:data.totalSummary
                };
                receipts.TableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                receipts.options= {
                    xAxisArray: ['Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                    seriesData: data.trendData.chartData,
                    modifyYear:false
                };
                if($state.params.type === "ALL_ULB" || $state.params.type === "ALL_WARD"){
                  receipts.TableData.records[0].limit =50;
                  receipts.TableData.records[0].infiniteScroll = true;
                }
                receipts.key=  query.locKey;
                receipts.type = query.locationType;
                receipts.TableData = formatTableData(receipts.TableData);
                vm.receipts =receipts
                vm.tabs[2].loadingData = false;
                if(isInitDistrict()==true){
                  RevenueServices.setstate(data,"Receipts");
                }
            }

        }

        function loadTop10Data() {
            console.log('Loading Top 10 content');
            vm.tabs[3].loadingData = true;
            var query = {
                locKey: $state.params.id || null,
                locationType : $state.params.type || null,
                currencyUnit : vm.conversionType
            };
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }
            if(isInitDistrict()==true && RevenueServices.getstate("Top10")){
              Top10Success(RevenueServices.getstate("Top10"));
              return;
            }

            RevenueServices.getTop10().query(query).$promise.then(Top10Success, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function Top10Success(data) {
                console.log('Top10 success!');
                var top10 ={};
                top10.TrendData = {};
                top10.TotalSummary ={};
                top10.TableData = {
                    records:data.revenueRecords,
                    locType:data.revenueRecords[0].locationType || "ULB",
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy"),
                };
                top10.options= {}
                top10.key=  query.locKey;
                top10.type = query.locationType;
                top10.TableData = formatTableData(top10.TableData);
                vm.top10 = top10;
                vm.tabs[3].loadingData = false;
                if(isInitDistrict()==true){
                  RevenueServices.setstate(data,"Top10");
                }

            }


        }

        function loadBottom10Data() {
            console.log('Loading Bottom10 content');
            vm.tabs[4].loadingData = true;
            var query = {
                locKey: $state.params.id || null,
                locationType : $state.params.type || null,
                currencyUnit : vm.conversionType
            };
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }
            if(isInitDistrict()==true && RevenueServices.getstate("Bottom10")){
              Bottom10Success(RevenueServices.getstate("Bottom10"));
              return;
            }


            RevenueServices.getBottom10().query(query).$promise.then(Bottom10Success, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function Bottom10Success(data) {
                console.log('Bottom10 success!');
                var bottom10 ={};
                bottom10.TrendData = {};
                bottom10.TotalSummary ={};
                bottom10.TableData = {
                    records:data.revenueRecords,
                    locType:data.revenueRecords[0].locationType || "ULB",
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                bottom10.options= {}
                bottom10.key=  query.locKey;
                bottom10.type = query.locationType;
                bottom10.TableData = formatTableData(bottom10.TableData);
                vm.bottom10 =bottom10;
                vm.tabs[4].loadingData = false;
                if(isInitDistrict()==true){
                  RevenueServices.setstate(data,"Bottom10");
                }

            }

        }

        function loadDefaultersData() {
            console.log('Loading Defaulters content');
            vm.tabs[5].loadingData = true;
            var query = {
                locKey: $state.params.id || null,
                locationType : $state.params.type || null,
                currencyUnit : vm.conversionType
            };
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }
            if(isInitDistrict()==true && RevenueServices.getstate("defaulters")){
              Top100DefaultersSuccess(RevenueServices.getstate("defaulters"));
              return;
            }


            RevenueServices.getTop100Defaulters().query(query).$promise.then(Top100DefaultersSuccess, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function Top100DefaultersSuccess(data) {
                console.log('Defaulters success!');
                var top100Defaulters ={};
                top100Defaulters.TrendData = {};
                top100Defaulters.TotalSummary ={};
                top100Defaulters.TableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                top100Defaulters.options= {}
                top100Defaulters.key=  query.locKey;
                top100Defaulters.type = query.locationType;
                top100Defaulters.TableData = formatTableData(top100Defaulters.TableData);
                vm.top100Defaulters =top100Defaulters;
                vm.tabs[5].loadingData = false;
                if(isInitDistrict()==true){
                  RevenueServices.setstate(data,"defaulters");
                }

            }

        }

        function loadCollectionRankingData() {
            console.log('Loading Collection Ranking Data');
            if($state.params.officertype){
              vm.officerType = $state.params.officertype;
            }
            if($state.params.officertype){
                vm.parentofficerType = $state.params.parentofficertype;
            }
            vm.tabs[6].loadingData = true;
            var query = {
                locKey: $state.params.id || null,
                locationType : $state.params.type || null,
                currencyUnit : vm.conversionType,
                officerType: vm.officerType,
                parentofficerType:vm.parentofficerType
            };
            if ($state.params.fromDate) {
                query.fromDate = convertDateTimezone($state.params.fromDate);
            }
            if ($state.params.toDate) {
                query.toDate =  convertDateTimezone($state.params.toDate);
            }
            // if(isInitDistrict()==true && RevenueServices.getstate("collectionRanking")){
            //   collectionRankingSuccess(RevenueServices.getstate("collectionRanking"));
            //   return;
            // }
            RevenueServices.getCollectionRankingData().query(query).$promise.then(collectionRankingSuccess, anyCallFail);
            //, collectionsSuccess, anyCallFail);
            function collectionRankingSuccess(data) {
                // console.log("data is ",data);
                console.log('Collection ranking success!');
                var collectionRankingData ={};
                collectionRankingData.TableData = {
                    records:data.revenueRecords,
                    locType:data.locationType,
                    formattedFromDate: $filter('date')(data.fromDate, "dd-MMM-yyyy"),
                    formattedToDate: $filter('date')(data.toDate, "dd-MMM-yyyy")
                };
                collectionRankingData.options= {}
                collectionRankingData.key=  query.locKey;
                collectionRankingData.type = query.locationType;
                collectionRankingData.TableData = formatTableData(collectionRankingData.TableData);
                vm.collectionRankingData = collectionRankingData;
                vm.tabs[6].loadingData = false;
                // if(isInitDistrict()==true){
                //   RevenueServices.setstate(data,"collectionRanking");
                // }
            }
        }

        function anyCallFail(error) {
            vm.tabs[0].loadingData = false;
            vm.tabs[1].loadingData = false;
            vm.tabs[2].loadingData = false;
            vm.tabs[3].loadingData = false;
            vm.tabs[4].loadingData = false;
            vm.tabs[5].loadingData = false;
            AlertService.error(error.data.message);
        }


    }


})();
